import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { UeberModule } from './ueber/ueber.module';
import { AusruestungModule } from './ausruestung/ausruestung.module';
//import { EinsaetzeModule } from './einsaetze/einsaetze.module';
import { DienstplanModule } from './dienstplan/dienstplan.module';
import { VereinModule } from './verein/verein.module';
import { MitgliedModule } from './mitglied/mitglied.module';
import { ImpressumModule } from './impressum/impressum.module';
import { DatenschutzModule } from './datenschutz/datenschutz.module';
import { AusbildungModule } from './ausbildung/ausbildung.module';
import { GeschichteModule } from './geschichte/geschichte.module';
//import { KontaktModule } from './kontakt/kontakt.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    UeberModule,
    AusruestungModule,
    //EinsaetzeModule,
    DienstplanModule,
    VereinModule,
    MitgliedModule,
    ImpressumModule,
    DatenschutzModule,
    AusbildungModule,
    GeschichteModule,
    //KontaktModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { UeberComponent } from '../ueber/ueber.component';
import { AusruestungComponent } from '../ausruestung/ausruestung.component';
import { AusbildungComponent } from '../ausbildung/ausbildung.component';
import { EinsaetzeComponent } from '../_obsolete/einsaetze/einsaetze.component';
import { DienstplanComponent } from '../dienstplan/dienstplan.component';
import { VereinComponent } from '../verein/verein.component';
import { GeschichteComponent } from '../geschichte/geschichte.component';
import { MitgliedComponent } from '../mitglied/mitglied.component';



@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    
    RouterModule.forRoot([
      {path: 'ueber', component: UeberComponent},
      {path: 'ausruestung', component: AusruestungComponent},
      {path: 'ausbildung', component: AusbildungComponent},
      {path: 'einsaetze', component: EinsaetzeComponent},
      {path: 'dienstplan', component: DienstplanComponent},
      {path: 'verein', component: VereinComponent},
      {path: 'geschichte', component: GeschichteComponent},
      {path: 'mitglied', component: MitgliedComponent}
    ])
  ],
  exports: [HomeComponent, RouterModule]
})
export class HomeModule { }

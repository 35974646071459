import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pl-verein',
  templateUrl: './verein.component.html',
  styleUrls: ['./verein.component.scss']
})
export class VereinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pl-ueber',
  templateUrl: './ueber.component.html',
  styleUrls: ['./ueber.component.scss']
})
export class UeberComponent implements OnInit {

  constructor() { 

  }

  ShowLoeschen(){
    document.getElementById('i_loeschen').style.display = "flex";
    document.getElementById('rlbs').style.display = "none";
  }
  ShowBergen(){
    document.getElementById('i_bergen').style.display = "flex";
    document.getElementById('rlbs').style.display = "none";
  }
  ShowSchuetzen(){
    document.getElementById('i_schuetzen').style.display = "flex";
    document.getElementById('rlbs').style.display = "none";
  }
  ShowRetten(){
    document.getElementById('i_retten').style.display = "flex";
    document.getElementById('rlbs').style.display = "none";
  }
  Close(){
    document.getElementById('rlbs').style.display = "inline-block";
    document.getElementById('i_loeschen').style.display = "none";
    document.getElementById('i_bergen').style.display = "none";
    document.getElementById('i_schuetzen').style.display = "none";
    document.getElementById('i_retten').style.display = "none";
  }

  ngOnInit(): void {
  }

}

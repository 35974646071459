<div id="content">
  <h1>Geschichtliches</h1>
  <div>
    <h4>Vereinsausflüge</h4>
    <p>Vereinsausflüge wurden in den letzten Jahren nach Bamberg, Reckendorf, Abensberg, Würzburg, 
      Straubing und Kulmbach gemacht. Weitere werden in den kommenden Jahren ebenso folgen, wie 
      die jährlichen Fahrradausflüge zu verschiedenen Biergärten.
    </p>

    <h4>Ehrungsabend</h4>
    <p>Am 16. Juli 2022 fand unsere große Feier zur Segnung unseres neuen LF20 KatS statt, die wegen COVID-19 auf den Sommer verschoben werden musste.
      Dabei wurden Herbert Hopfes, Dieter Baier und Peter Beier zum Ehrenmitglied ernannt, ebenso haben Ehrungen für den langjährigen aktiven Dienst stattgefunden.
    </p>

    <h4>Kommandantenwahl</h4>
    <p>Am 14. September 2021 wurde Bernd Hopfes erneut als erster Kommandant gewählt und Maximilian Lodes als sein Stellvertreter.</p>

    <h4>LF20 KatS - Löschgruppenfahrzeug Katastrophenschutz</h4>
    <p>Am 03.09.2021 wurde das neue LF20 KatS gesegnet.</p>
    <div class="img">
      <img src="../../assets/03_LF20KatS.jpg" alt="">
    </div>

    <h4>Neuwahlen Vorstandschaft 2021</h4>
    <p>1. Vorsitzender: Jörg Jordan
      <br>2. Vorsitzende: Lisa Scherzer 
      <br>Schriftführer: Markus Jordan 
      <br>Aktivenvertreter: Christian Amm, Katharina Frötsch
      <br>1. Kassier: Frank Jordan 
      <br>2. Kassier: Simon Denker
    </p>

    <h4>Ehrungen</h4>
    <p>Peter Jordan wird 2018 nach 24 Dienstjahren zum Ehrenkommandanten ernannt.
      Dieter Baier wird von Bayerns Innenminister Joachim Herrmann für 50 Jahre aktiven Dienst bei
      der Feuerwehr geehrt. Dies war zum damaligen Zeitpunkt (2018) aufgrund einer Gesetzesänderung
      von 2016 das erste Mal möglich, denn das Höchstalter für den aktiven Dienst wurde von 63 Jahren
      auf 65 Jahren geändert. 
    </p>
    <div>
      <a id="dieter" href="https://www.infranken.de/lk/gem/50-jahr-aktiver-dienst-grosses-ehrenzeichen-fuer-dieter-baier-art-3639517"
              title="https://www.infranken.de/lk/gem/50-jahr-aktiver-dienst-grosses-ehrenzeichen-fuer-dieter-baier-art-3639517">
        <img src="../../assets/08_Ehrungen.jpg" alt="https://www.infranken.de/lk/gem/50-jahr-aktiver-dienst-grosses-ehrenzeichen-fuer-dieter-baier-art-3639517">
        <p></p>
      </a>
    </div>

    <h4>Fahnenweihe</h4>
    <p>2018 wurde die neue Fahne eingeweiht. Fahnenpatin ist Katharina Frötsch. </p>
    <div class="img"> 
      <img src="../../assets/08_Fahnenweihe.jpg" alt="">
    </div>

    <h4>Kommandantenwahl</h4>
    <p>Seit 2015 ist Bernd Hopfes 1. Kommandant und Sven Baier sein Stellvertreter.</p>

    <h4>Vereinsaktivitäten</h4>
    <p>Bis 2013 gab es jedes Jahr einen Faschingsball mit vier Vereinen aus Aurachtal. Dieser wurde in 
      der Schulturnhalle in Münchaurach durchgeführt.
      <br> Seit 2012 gibt es bei der Falkendorfer Feuerwehr ein Herbstgrillfest.
    </p>

    <h4>Neuwahlen Vorstandschaft und Ehrungsabend 2011</h4>
    <p>1. Vorsitzender: Jörg Jordan
      <br>2. Vorsitzende: Lisa Scherzer 
      <br>Schriftführer: Markus Jordan 
      <br>Aktivenvertreter: Christian Amm, Sabrina Wolf (seit 2017 Katharina Frötsch)
      <br>1. Kassier: Frank Jordan 
      <br>2. Kassier: Stefan Krämer
      <br><br>Nach 25 Jahren als 1. Vorsitzender wurde Heinz-Udo Maier zum Ehrenvorstand ernannt.
    </p>

    <h4>Mehrzweckfahrzeug</h4>
    <p>Am 16.05.2010 wurde das neue MZF Ford Transit eingeweiht.</p>
    <div class="img">
      <img src="../../assets/03_MZF.JPG" alt="">
    </div>

    <h4>Feuerwehrfest</h4>
    <p>Im Jahr 2004 fand unser Feuerwehrfest statt. Die Feuerwehr wurde 125 Jahre alt.</p>
    <div>
      <div class="img">
        <img class="img_a" src="../../assets/08_feuerwehrfest.png" alt="">
      </div>
    </div>

    <h4>Mehrzweckfahrzeug</h4>
    <p>Im November 1998 wurde ein Mehrzweckfahrzeug angeschafft. </p>
    <div class="img">
      <img src="../../assets/08_MZF.jpg" alt="">
    </div>

    <h4>Falkendorfer Kerwa</h4>
    <p>Da sich die Gastronomie und auch sonst keiner mehr der Falkendorfer Kerwa habhaft werden 
      wollte, wird sie seit 1993 von der Falkendorfer Feuerwehr ausgerichtet. Bis heute ist sie 
      ein voller Erfolg.
    </p>

    <h4>Feuerwehrauto</h4>
    <p>Vom 31. August bis zum 2. September 1990 wurde das erste Feuerwehrauto eingeweiht.
    </p>
    <div class="img">
      <img src="../../assets/08_LF8.jpg" alt="">
    </div>

    <h4>Gerätehauseinweihung</h4>
    <p>Vom 10. bis 12. Juni 1989 wurde das neue Gerätehaus eingeweiht. </p>
    <div class="img">
      <img src="../../assets/08_Gerätehaus.jpg" alt="">
    </div>

    <h4>Erfolg der Damen</h4>
    <p>1981 absolvierte die erste Damengruppe im Landkreis das Leistungsabzeichen </p>
    <div class="img">
      <img src="../../assets/Zeitungsartikel.jpg" alt="">
    </div>

    <h4>Anstieg der Feuerwehr Falkendorf</h4>
    <p>1977 beschloss man, ein Feuerwehrfest abzuhalten (im Jahre 1979). Ebenso wurde ein Antrag 
      eines Neubaus des Gerätehauses an die Gemeinde gestellt. Zahlreiche Jugendliche traten der 
      Jugendfeuerwehr bei und es gab eine erste komplette Löschgruppe, die nur aus Mädchen/Frauen bestand. 
    </p>
    <div class="img">
      <img src="../../assets/08_Mädchen.jpg" alt="">
    </div>

    <h4>Serie von Brandeinsätzen</h4>
    <p>1973 begann die Serie der Brandeinsätze, da durch zündelnde Kinder 3 ha Wald vernichtet wurden. 
      Ebenso hatten zündelnde Kinder erneut fast zwei Anwesen in Hauptendorf 1974 vernichtet. Der Schauplatz
      der nächsten drei Einsätze waren immer Scheunen verschiedener Anwesen von 1974 bis 1976. Bei allen ging
      man von Brandstiftung aus. 1976 entzündete sich ein Heustock in Unterreichenbach selbst, bei dem durch
      schnelles Eingreifen der Wehr ein größerer Schaden vermieden werden konnte. Abermals brannte eine 
      Scheune, diesmal in Neundorf im Jahr 1978.
    </p>

    <h4>Feuerwehr wieder ins Leben gerufen</h4>
    <p>1963 wurde die Feuerwehr wieder ins Leben gerufen und hatte 90 Mitglieder.
      <br><br>1953 gab es ein vorzeitiges Ende der Aufzeichnungen.
      <br>1950 wurde eine Neugründerversammlung abgehalten. 
      <br>1919 gab es 46 Aktive.
      <br>1903 wurde an Ostern eine zweite Spritze angeschafft. 
      <br>1901 wurde Michael Kreß zum 1. Vorstand wiedergewählt.
      <br>Weitere Aufzeichnungen bis 1900 wurden durch Witterungseinflüsse vernichtet. 
    </p>

    <h4>Pflichtfeuerwehr</h4>
    <p>Eine sogenannte Pflichfeuerwehr wurde 1883 gegründet, in der die Feuerwehrkameraden dazu
       verpflichtet wurden, in der Feuerwehr tätig zu sein, da sich nicht genug Freiwillige finden
        ließen. Außerdem wurden die ersten Alarmsignale eingeführt.
      </p>

    <h4>Erste Aufzeichnungen</h4>
    <p>Schon vor 1865 gab es Aufzeichnungen von Feuerlösch-Requisiten in Falkendorf. Im Jahr 1865 beschloss
       man, sich eine Feuerlöschmaschine anzuschaffen, da in den Jahren zuvor Falkendorf von mehreren 
       Großbränden heimgesucht wurde. 
    </p>
    <div >
      <div id="ersteAufzeichnungen" class="img">
        <img src="../../assets/08_Niederschrift.jpg" alt="">
        <img src="../../assets/08_Pumpe.jpg" alt="">
      </div>
    </div>
  </div>
</div>
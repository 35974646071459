import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AusruestungRoutingModule } from './ausruestung-routing.module';
import { AusruestungComponent } from './ausruestung.component';


@NgModule({
  declarations: [AusruestungComponent],
  imports: [
    CommonModule,
    AusruestungRoutingModule
  ],
  exports: [AusruestungComponent]
})
export class AusruestungModule { }

import { Component, OnInit } from '@angular/core';
import { execFile } from 'child_process';
import { xlsxFile} from 'read-excel-file';

@Component({
  selector: 'pl-einsaetze',
  templateUrl: './einsaetze.component.html',
  styleUrls: ['./einsaetze.component.scss']
})
export class EinsaetzeComponent implements OnInit {

  constructor() {

   
  }

  ngOnInit(): void {
    
  }

  //2022
  show2022(num){
    document.getElementById('visible_2022_' + num).style.display = "none";
    document.getElementById('hidden_2022_' + num).style.display = "flex";
  }
  notShow2022(num){
    document.getElementById('visible_2022_' + num).style.display = "flex";
    document.getElementById('hidden_2022_' + num).style.display = "none";
  }
  showEinsatz2022(){
    document.getElementById('einsätze_2022').style.display = "grid";
    document.getElementById('pfeil_hidden_2022').style.display = "flex";
    document.getElementById('pfeil_visible_2022').style.display = "none";
  }
  notShowEinsatz2022(){
    document.getElementById('einsätze_2022').style.display = "none";
    document.getElementById('pfeil_visible_2022').style.display = "flex";
    document.getElementById('pfeil_hidden_2022').style.display = "none";
  }
  
  //2023
  show2023(num){
    document.getElementById('visible_2023_' + num).style.display = "none";
    document.getElementById('hidden_2023_' + num).style.display = "flex";
  }
  notShow2023(num){
    document.getElementById('visible_2023_' + num).style.display = "flex";
    document.getElementById('hidden_2023_' + num).style.display = "none";
  }
  showEinsatz2023(){
    document.getElementById('einsätze_2023').style.display = "grid";
    document.getElementById('pfeil_hidden_2023').style.display = "flex";
    document.getElementById('pfeil_visible_2023').style.display = "none";
  }
  notShowEinsatz2023(){
    document.getElementById('einsätze_2023').style.display = "none";
    document.getElementById('pfeil_visible_2023').style.display = "flex";
    document.getElementById('pfeil_hidden_2023').style.display = "none";
  }


}

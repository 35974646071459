import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AusbildungRoutingModule } from './ausbildung-routing.module';
import { AusbildungComponent } from './ausbildung.component';


@NgModule({
  declarations: [AusbildungComponent],
  imports: [
    CommonModule,
    AusbildungRoutingModule
  ],
  exports: [AusbildungComponent]
})
export class AusbildungModule { }

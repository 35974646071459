<h1>Impressum</h1>
<div id="content">

  <h2>Angaben gemäß § 5 DDG</h2>
  <p>Freiwillige Feuerwehr Falkendorf <br>
    Hauptstraße 1 <br>
    91086 Aurachtal <br><br>
    Vertreten durch: <br>
    Jörg Jordan
  </p>
  <h2>Kontakt</h2>
  <p>E-Mail: ff-falkendorf@web.de
  </p>
  <h2>Umsatzsteuer-ID</h2>
  <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: <br>
    216/108/42833
  </p>
  <h2>Redaktionell Verantwortlicher</h2>
  <p>Jörg Jordan <br>
    Hauptstraße 1 <br>
    91086 Aurachtal <br>
  </p>
  <h2>EU-Streitschlichtung</h2>
  <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
    <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.
    Unsere E-Mail-Adresse finden Sie oben im Impressum.
  </p>
  <h2>Verbraucherstreitbeilegung/ Universalschlichtungsstelle</h2>
  <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einerVerbraucherschlichtungsstelle teilzunehmen.</p>
  <h3>Haftung für Inhalte</h3>
  <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach denallgemeinen Gesetzen verantwortlich. 
    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu 
    überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. <br><br>
    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinenGesetzen bleiben hiervon unberührt. 
    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt derKenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
     von entsprechendenRechtsverletzungen werden wir diese Inhalte umgehend entfernen. 
  </p>
  <h3>Haftung für Links</h3>
  <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
    Inhalte auch keine Gewähr übernehmen. Für die Inhalte derverlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
    Die verlinktenSeiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhaltewaren zum Zeitpunkt 
    der Verlinkung nicht erkennbar. <br><br>
    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einerRechtsverletzung nicht zumutbar.
    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Linksumgehend entfernen.
  </p>
  <h3>Urheberrecht</h3>
  <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschenUrheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art 
    der Verwertung außerhalb derGrenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.Downloads und Kopien dieser Seite sind nur für 
    den privaten, nicht kommerziellen Gebrauch gestattet. <br><br>
    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritterbeachtet. Insbesondere 
    werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. BeiBekanntwerden 
    von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. 
    <br><br>
    Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a> </p>
</div>





import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pl-ausruestung',
  templateUrl: './ausruestung.component.html',
  styleUrls: ['./ausruestung.component.scss']
})
export class AusruestungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showLF8(){
    document.getElementById('hidden_lf8').style.display = "flex";
    document.getElementById('lf8').style.display = "none";
  }
  notShowLF8(){
    document.getElementById('hidden_lf8').style.display = "none";
    document.getElementById('lf8').style.display = "flex";
  }
}

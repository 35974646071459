<div id="content">
  <h1>Mach mit!</h1>

  <div>
    <p id="fragen">Du bist 12 Jahre alt oder älter und hast Lust, anderen zu helfen? 
      <br>Du willst dich ehrenamtlich engagieren und neue Kameraden kennenlernen?
      <br>Du möchtest einer spannenden und sinnvollen Tätigkeit nachgehen?
      <br><br>Dann heißt es nur:
    </p>
    <h4>Wir suchen DICH!</h4>
    <p>Ab 12 Jahren kannst du zur Jugendfeuerwehr kommen und schon alles lernen,
        was später für Einsätze wichtig ist. Ab 16 Jahren geht es dann auf Einsätze, wenn man die MTA erfolgreich abgeschlossen hat.
        Jedoch nur bis zu einem bestimmten sicheren Bereich. Ab 18 Jahren darfst du den Atemschutz-, Maschinistenlehrgang oder auch viele 
        weitere machen.
    </p>
    <div id="img_1">
      <img src="../../assets/09_jfw_1.JPG" alt="">
    </div>
    <div id="img_2">
      <img id="img_2_1" src="../../assets/09_jfw_2.jpg" alt="">
      <img id="img_2_2" src="../../assets/09_jfw_3.jpg" alt="">
    </div>
  </div>
    
  <div>
    <h3>Interesse oder Fragen?</h3>
    <p>…dann komme doch einfach zu unserer nächsten Übung, oder wenn dir das zu lange dauert,
      schicke uns gerne eine Mail an: <a href="mailto:ff-falkendorf@web.de">ff-falkendorf@web.de</a>
    </p>
    <div id="kontakt">
      <!--<div>
        <p>Mitgliederantrag an: j-jordan@gmx.de</p>
        <a href="../../assets/Aufnahmeantrag.pdf">Aufnahmeantrag</a>
      </div> -->
      <div>
        <p>Wenn du dir noch nicht sicher bist, dann schaue doch einmal bei unseren Übungen vorbei. 
          Die Termine hierzu findest du im Dienstplan. Treffpunkt ist immer zur angegebenen Zeit an 
          unserem Feuerwehrgerätehaus:
          <br><br>Hauptstraße 1 
          <br>91086 Falkendorf
        </p>
      </div>
    </div>
  </div>

</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DienstplanRoutingModule } from './dienstplan-routing.module';
import { DienstplanComponent } from './dienstplan.component';


@NgModule({
  declarations: [DienstplanComponent],
  imports: [
    CommonModule,
    DienstplanRoutingModule
  ],
  exports: [DienstplanComponent]
})
export class DienstplanModule { }

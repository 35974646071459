import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VereinRoutingModule } from './verein-routing.module';
import { VereinComponent } from './verein.component';


@NgModule({
  declarations: [VereinComponent],
  imports: [
    CommonModule,
    VereinRoutingModule
  ],
  exports: [VereinComponent]
})
export class VereinModule { }

<div id="content">
  <div id="rlbs">
    <a id="signet" href="https://www.feuerwehrversand.de/1/Home.htm">
      <img src="../../assets/02_Signet.png" alt="" >
    </a>
    <div id="grid_rlbs">
      <div class="button" id="loeschen" (click)="ShowLoeschen()">
        <h1>Löschen</h1>
      </div>
      <div class="button" id="bergen" (click)="ShowBergen()">
        <h1>Bergen</h1>
      </div>
      <div class="button" id="schuetzen" (click)="ShowSchuetzen()">
        <h1>Schützen</h1>
      </div>
      <div class="button" id="retten" (click)="ShowRetten()">
        <h1>Retten</h1>
      </div>
    </div>
  </div>

  <div class="content_rlbs" id="i_loeschen">
    <div class="txt_rlbs">
      <p (click)="Close()" class="zurueck"><b>&#10007;</b></p>
      <a class="signet_klein" href="https://www.feuerwehrversand.de/1/Home.htm">
        <img src="../../assets/02_Signet.png" alt="" >
      </a>
      <p class="txt">Das <strong>Löschen</strong> zählt zu den ältesten Aufgaben der Feuerwehr und beinhaltet das Bekämpfen von Feuer jeglicher Art. 
        Ob als Hausbrand, Fahrzeugbrand oder Brand gefährlicher Stoffe. Jedoch ist die Anzahl der zu löschenden Brände 
        im Vergleich zu anderen Tätigkeiten zurückgegangen.
      </p>
    </div>
    <div class="pic_rlbs">
      <img src="../../assets/02_löschen_01.jpg" alt="">
      <img src="../../assets/02_löschen_02.jpg" alt="">
    </div>
  </div>

  <div class="content_rlbs" id="i_bergen">
    <div class="txt_rlbs">
      <p (click)="Close()" class="zurueck"><b>&#10007;</b></p>
      <a class="signet_klein" href="https://www.feuerwehrversand.de/1/Home.htm">
        <img src="../../assets/02_Signet.png" alt="" >
      </a>
      <p class="txt"><strong>Bergen</strong> von Sachgütern, toten Menschen und Tieren, aber vor allem die technische Hilfeleistung ist heutzutage 
        ein sehr großer Aufgabenbereich der Feuerwehr geworden. Nicht nur bei Autounfällen, sondern auch bei 
        Überschwemmungen, Explosionen und ähnlichen Ereignissen sind wir zur Stelle.
      </p>
    </div>
    <div class="pic_rlbs">
      <img src="../../assets/02_bergen_01.jpg" alt="">
      <img src="../../assets/02_bergen_02.jpg" alt="">
    </div>
  </div>

  <div class="content_rlbs" id="i_schuetzen">
    <div class="txt_rlbs">
      <p (click)="Close()" class="zurueck"><b>&#10007;</b></p>
      <a class="signet_klein" href="https://www.feuerwehrversand.de/1/Home.htm">
        <img src="../../assets/02_Signet.png" alt="" >
      </a>
      <p class="txt">Das <strong>Schützen</strong> beinhaltet den vorbeugenden Brandschutz, um eine mögliche Gefahr zu senken. Entstehungsbrände 
        werden vermieden oder bei öffentlichen Veranstaltungen wird der Schutz mittels einer Brandwache gewährleistet. 
        Beim aktiven Umweltschutz wird die Umwelt bei Ölunfällen oder anderen Unfällen von giftigen und schädlichen 
        Chemikalien geschützt.
      </p>
    </div>
    <div class="pic_rlbs">
      <img src="../../assets/02_schuetzen_01.jpg" alt="">
      <img src="../../assets/02_schuetzen_02.jpg" alt="">
    </div>
  </div>

  <div class="content_rlbs" id="i_retten">
    <div class="txt_rlbs">
      <p (click)="Close()" class="zurueck"><b>&#10007;</b></p>
      <a class="signet_klein" href="https://www.feuerwehrversand.de/1/Home.htm">
        <img src="../../assets/02_Signet.png" alt="" >
      </a>
      <p class="txt">Die Feuerwehr <strong>rettet</strong> nicht nur Menschen und Tieren aus lebensbedrohlichen Situationen, sondern führt auch 
        technische Rettungsmaßnahmen durch. Dazu werden bei Menschen, die lebensgefährlich verletzt worden sind, 
        Sofortmaßnahmen eingeleitet, um sie aus einer Zwangslage zu befreien.
      </p>
    </div>
    <div class="pic_rlbs">
      <img src="../../assets/02_retten_01.jpg" alt="">
      <img src="../../assets/02_retten_02.jpg" alt="">
    </div>
  </div>

  <div id="txt_content">
    <div id="fuehrung">
      <h3>Feuerwehrführung</h3>
      <div>
        <div class="kommandant">
          <img src="../../assets/02_Bernd.jpg" alt="">
          <p>Kommandant <br>Bernd Hopfes</p>
        </div>
        <div class="kommandant">
          <img src="../../assets/02_Max.jpg" alt="">
          <p>Stellv. Kommandant <br>Maximilian Lodes</p>
        </div>
      </div>
    </div>
    <div id="mannschaft">
      <h3>Mannschaft</h3>
      <p>Unsere Mannschaft besteht derzeit aus 38 Aktiven. Darunter 30 Männer und 8 Frauen. Auch die Jugendfeuerwehr ist tatkräftig dabei.
        <br>Um unseren Zusammenhalt zu fördern, unternehmen wir jedes Jahr etwas zusammen und 
        treffen uns auch abgesehen von Übungen und Einsätzen.
      </p>
    </div>
    <div id="pct_mannschaft">
      <img src="../../assets/02_mannschaft_01.jpg" >
      <img src="../../assets/02_mannschaft_02.jpg" >
    </div>
    
    <!-- 
    <div id="gruppeneinteilung">
      <h3>Gruppeneinteilung</h3>
      <h4>Gruppenführer</h4>
      <div id="gruppe">
        <p id="gruppe_1">Gruppe 1</p>
        <p id="gruppe_2">Gruppe 2</p>
        <p id="gruppe_3">Gruppe 3</p>
        <p id="gruppe_4">Gruppe 4</p>
        <p class="red" id="gruppe_1_n">Bernd Hopfes</p>
        <p class="red" id="gruppe_2_n">Sven Baier</p>
        <p class="red" id="gruppe_3_n">Jörg Jordan</p>
        <p class="red" id="gruppe_4_n">Carmen Christof</p>
      </div>
    </div>-->

    <div id="uebung">
      <h3>Einblicke in mehrere Übungen</h3>
      <div class="einblick">
        <p>Übung mit Feuerlöschern und Ausführen eines Experimentes: Löschen von brennendem Fett mit Wasser</p>
      </div>
      <div class="img">
        <img src="../../assets/02_einblick_02.jpg" alt="">
        <img src="../../assets/02_einblick_01.jpg" alt="">
      </div>
  
      <div class="einblick">
        <p>Übung der Atemschutzgeräteträger vor deren Prüfung</p>
      </div>
      <div class="img">
        <img src="../../assets/02_einblick_03.jpg" alt="">
      </div>
  
      <div class="einblick">
        <p>Einsatzübung mit der Feuerwehr Herzogenaurach: brennendes Haus mit mehreren verletzten Personen</p>
      </div>
      <div class="img">
        <img src="../../assets/02_einblick_05.jpg" alt="">
        <img src="../../assets/02_einblick_06.jpg" alt="">
      </div>
      
    </div>

  </div> 



</div>



<head>

</head>
<body>
  <div id="kopf">
    <!-- Logo -->
    <div id="logo"> 
      <img id="wappen" src="../assets/a_Falke + Wappen.png" alt="Wappen">
      <h1>Freiwillige Feuerwehr Falkendorf</h1>
    </div> 
    <!-- Toggled Navbar -->
    <div id="hamburger">
      <nav id="nav_hamburger" class="navbar navbar-expand-lg navbar-dark bg-dark" style="width: 100%; justify-content: center;">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse show" [ngClass]="{'show': navbarOpen}">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="home" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="ueber" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Über uns</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="ausruestung" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Ausrüstung</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="ausbildung" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Ausbildung</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" id="point" routerLink="einsaetze" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Einsätze</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="dienstplan" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Dienstplan</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="verein" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Vorstandschaft</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="geschichte" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Verein - Geschichte</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="point" routerLink="mitglied" routerLinkActive="active-route" [routerLinkActive]="['active']" (click)="toggleNavbar()">Mach mit!</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- Navbar -->
    <div>
      <nav id="menu_list">
        <div id="nav">
          <a id="btn_home" routerLink="home" (click)="notVereinBackground()" routerLinkActive="active-route" [routerLinkActive]="['active']" >Aktuelles</a>
          <a id="btn_ueberUns" routerLink="ueber" (click)="notVereinBackground()" routerLinkActive="active-route" [routerLinkActive]="['active']">Über uns</a>
          <a id="btn_ausruestung" routerLink="ausruestung" (click)="notVereinBackground()" routerLinkActive="active-route" [routerLinkActive]="['active']">Ausrüstung</a>
          <a id="btn_ausbildung" routerLink="ausbildung" (click)="notVereinBackground()" routerLinkActive="active-route" [routerLinkActive]="['active']">Ausbildung</a>    
<!-- 
<a id="btn_einsaetze" routerLink="einsaetze" (click)="notVereinBackground()" routerLinkActive="active-route" [routerLinkActive]="['active']">Einsätze</a>
-->          
          <a id="btn_dienstplan" routerLink="dienstplan" (click)="notVereinBackground()" routerLinkActive="active-route" [routerLinkActive]="['active']">Dienstplan</a>
          <a id="verein" (mouseover)="showVerein()" (mouseout)="notShowVerein()" >Verein</a>
          <a id="btn_mitglied" (click)="notShowBackground()" (click)="notVereinBackground()" routerLink="mitglied" routerLinkActive="active-route" [routerLinkActive]="['active']">Mach mit!</a>
        </div>
        <div id="verein2" [ngStyle]="{display: 'none'}" (mouseover)="showVerein()" (mouseout)="notShowVerein()" >
          <a id="btn_verein" (click)="notShowVerein()" (click)="vereinBackground()" routerLink="verein" routerLinkActive="active-route" [routerLinkActive]="['active']" >Vorstandschaft</a>
          <a id="btn_geschichte" (click)="notShowVerein()" (click)="vereinBackground()" routerLink="geschichte" routerLinkActive="active-route" [routerLinkActive]="['active']">Geschiche</a>
        </div>
      </nav>
    </div>
  </div>

  <!-- Seite -->
  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
  <div id="footer">
    <div id="location">
      <img id="icon_location" src="../assets/z_location.png" alt="">
        <a id="maps" href="https://www.google.de/maps/place/Freiwillige+Feuerwehr+Falkendorf/@49.5788447,10.836436,17z/data=!3m1!4b1!4m5!3m4!1s0x47a20703be968261:0xc1f08097d881fac!8m2!3d49.5788412!4d10.83863">
          <p>Freiwillige Feuerwehr Falkendorf</p>
          <p>Hauptstraße 1 </p>
          <p>91086 Aurachtal</p>
        </a>
    </div>
    <div id="mail">
      <div>
        <p>Notruf: 112</p>
      </div>
      <div>
        <img class="icon" src="../assets/z_mail.png" alt="mail">
        <a href="mail:ff-falkendorf@web.de">ff-falkendorf@web.de</a>
      </div>
      <!-- 
      <div>
        <img class="icon" src="../assets//z_phone.png" alt="phone">
        <p>09132 63930</p>
      </div>
       -->
    </div>
    <div id="impressum">
      <p id="btn_impressum" class="button" (click)="notVereinBackground()" routerLink="impressum" routerLinkActive="active-route">Impressum</p>
      <p id="btn_datenschutz" class="button" (click)="notVereinBackground()" routerLink="datenschutz" routerLinkActive="active-route">Datenschutz</p>
    </div>
  <!-- 
    <div id="social">
      <a href="https://www.facebook.com/pages/category/Community/Freiwillige-Feuerwehr-falkendorf-984304454973183/">
        <img src="../assets/z_facebook.png" alt="facebook" >
      </a>
    </div>
  -->
  </div>
</body>




<div id="content">
  <h1>Ausbildung und Lehrgänge</h1>
  <div id="img_ausbildung">
    <img  src="../../assets/04_ausbildung.jpg" alt="">
  </div>
  

  <div id="sfsw">
    <p>Feuerwehrmänner und Frauen können sich in speziellen Lehrgängen weiterbilden lassen,
      um der heutigen Bekämpfung von Gefahren gerecht zu werden. Deshalb finden auf 
      Landkreisebene Ausbildungen und Lehrgänge mit anderen Feuerwehren an ausgewählten 
      Standorten statt bzw. auch an den Staatlichen Feuerwehrschulen in Bayern (z.B. in Würzburg).
      <br><br>Die Staatliche Feuerwehrschule Würzburg hat unter anderem eine <a href="https://www.feuerwehr-lernbar.bayern/home/">Lernbar</a> entwickelt,
       bei der man sich verschiedene Unterrichtsmaterialien downloaden und sein Wissen erweitern kann.
      </p>
      <div id="img_sfsw">
        <a href="https://www.sfs-w.de/startseite.html">
          <img src="../../assets/04_SFSW_Logo.png" alt="">
        </a>
      </div>
      
  </div>

  <div id="truppausbildung" class="background">
    <h3>Truppausbildung</h3>
    <div>
      <div id="truppmann" >
        <h4>Truppmann</h4>
        <p>Jeder Feuerwehrmann und jede Feuerwehrfrau durchläuft am Anfang eine sogenannte 
          MTA - Modulare Truppausbildung. Dort lernt man grundlegende Dinge in Theorie und 
          Praxis, wie zum Beispiel:
        </p>
        <ul>
          <li>Organisation der Feuerwehr</li>
          <li>Löschwasserversorgung</li>
          <li>Einsatzhygiene</li>
          <li>Hilfeleistungseinsatz</li>
          <li>Verhalten bei Gefahr</li>
          <li>Verhalten im Einsatz und in der Öffentlichkeit</li>
          <li>Sprechfunk</li>
          <li>Gerätekunde</li>
          <li>Erste-Hilfe-Kurs</li>
          <li>... und noch vieles mehr</li>
        </ul>
      </div>
      <div id="img_tm" class="ausbildung">
        <img id="img_tm_01" src="../../assets/04_Tm_01.JPG" alt="">
        <img id="img_tm_02" src="../../assets/04_Tm_02.jpg" alt="">
        <img id="img_tm_03" src="../../assets/04_Tm_03.jpg" alt="">
      </div>
      <div id="truppfuehrer" >
        <h4>Truppführer</h4>
        <p>Nach dem Truppmann hat man zwei Jahre Zeit, all das gelernte in der Praxis auch 
          zu Üben und Einsatzerfahrung zu sammeln. Danach schließt man die MTA mit dem 
          Truppführer ab, bei dem man nun eigenständig einen Trupp führen darf. Der Truppführer 
          trägt die Verantwortung für sich und seinen Truppmann.
        </p>
      </div>
      <div id="img_tf" class="ausbildung">
        <img src="../../assets/04_Tf_02.JPG" alt="">
        <img src="../../assets/04_Tf_01.JPG" alt="">
      </div>
    </div>
  </div>

  <div id="technischeAusbildung" class="background">
    <h3>Technische Ausbildung</h3>

    <div id="atemschutz_txt">
      <h4>Atemschutzgeräteträger</h4>
      <p>Erst mit dieser Weiterbildung darf man sich mit Atemluftflasche, Maske und Co. 
        Ausrüsten. Man ist Teil dessen Trupps, der beispielsweise in ein brennendes Haus 
        eindringt, um das Feuer von innen zu bekämpfen und Personen aus dem Gefahrenbereich 
        zu retten. Immer dann, wenn sich giftige Bestandteile in der Luft befinden, braucht 
        ein Feuerwehrmann oder eine Feuerwehrfrau das Atemschutzgerät. So wird verhindert, 
        dass giftige Stoffe in die Augen oder Atemwege geraten.
      </p>
    </div>
    <div id="atemschutz_img" class="ausbildung">
      <img src="../../assets/04_Agt_01.jpg" alt="">
      <img src="../../assets/04_Agt_02.jpg" alt="">
      <img src="../../assets/04_Agt_03.jpg" alt="">
      <img src="../../assets/04_Agt_04.jpg" alt="">  
    </div>
    
    <div id="maschinist_txt">
      <h4>Maschinist</h4>
      <p>Der Maschinist ist Fahrer des Feuerwehrfahrzeuges und bringt die Mannschaft und 
        das Fahrzeug sicher an den Einsatzort. Ebenso bedient er die Tragkraftspritze, 
        die Kreiselpumpe am Fahrzeug und alle anderen im Fahrzeug befindlichen Geräte.
      </p>
    </div>
    <div id="maschinist_img" class="ausbildung">
      <img src="../../assets/04_Ma_01.jpg" alt="">
      <img src="../../assets/04_Ma_02.jpg" alt="">
      <img id="img_ma_03" src="../../assets/04_Ma_03.jpg" alt="">
    </div>
  </div>  

  <div id="gruppenführer"  class="background">
    <div id="gf_txt">
      <h4>Gruppenführer</h4>
      <p>Der Gruppenführer leitet seine ihm zugeteilte Gruppe aus neun Einsatzkräften 
        (inkl. Gruppenführer) während des Einsatzes und der Übungen und übernimmt die 
        Verantwortung über alle. Er erkundet die Lage und erteilt die Einsatzbefehle innerhalb seiner Gruppe.
      </p>
    </div>
    <div id="gruppenfuehrer_img" class="ausbildung">
      <img src="../../assets/04_Gf_01.JPG" alt="">
      <img src="../../assets/04_Gf_02.JPG" alt="">
    </div>
  </div>

</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MitgliedRoutingModule } from './mitglied-routing.module';
import { MitgliedComponent } from './mitglied.component';


@NgModule({
  declarations: [MitgliedComponent],
  imports: [
    CommonModule,
    MitgliedRoutingModule
  ],
  exports: [MitgliedComponent]
})
export class MitgliedModule { }

<div id="content">
  <h1>Vorstandschaft</h1>

  <div id="vorstand">
    <h3>Vorstandschaft</h3>
    <div>
      <div id="img_vorstandschaft" >
        <img src="../../assets/07_Vorstandschaft.jpg" alt="">
      </div>
      <div id="name">
        <div>
          <p>1. Vorsitzender: Jörg Jordan 
            <br>2. Vorsitzende: Lisa Scherzer</p>
        </div> 
        <div>
          <p>1. Kassier: Frank Jordan
            <br> 2. Kassier: Simon Denker</p>
        </div>
        <div>
          <p>Schriftführer: Markus Jordan
            <br> Aktivenvertreter: Christian Amm, Katharina Frötsch</p>
        </div>
        <div>
          <p>1. Kommandant: Bernd Hopfes
            <br> Stellv. Kommandant: Maximilian Lodes</p>
        </div>
      </div>
    </div>
    
  </div>

  <div id="ehrenmitglieder">
    <h3>Ehrenmitglieder des Vereins</h3>
    <div id="grid_ehrung">
      <div>
        <h4>Ehrenmitglieder:</h4>
        <p>Andreas Scherzer
          <br>Anton Wolf
          <br>Heinz-Udo Maier
          <br>Erwin Wolf
          
        </p>
      </div>
      <div>
        <p>  <br>Hermann Schuh
          <br>Leonhard Schmidt
          <br>Lorenz Jordan
          <br>Ludwig Jordan
          <br>Matthäus Andree
        </p>
      </div>
      <div>
        <p> <br>Peter Ortegel
          <br>Richard Sänger
          <br>Herbert Hopfes
          <br>Dieter Baier
          <br>Peter Beier
        </p>
      </div>
      <div>
        <h4>Ehrenvorstand:</h4>
        <p>Heinz-Udo Maier</p>
        <h4>Ehrenkommandanten:</h4>
        <p>Peter Jordan
          <br>Richard Sänger
        </p>
      </div>
    </div>
    <img src="../../assets/07_ehrenmitglieder.jpg" alt="">
  </div>

  <div id="fahne">
    <h3>Fahne</h3>
    <div>
      <img src="../../assets/07_Fahne_01.JPG" alt="">
      <img src="../../assets/07_Fahne_02.JPG" alt="">
    </div>
  </div>
  
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UeberRoutingModule } from './ueber-routing.module';
import { UeberComponent } from './ueber.component';


@NgModule({
  declarations: [UeberComponent],
  imports: [
    CommonModule,
    UeberRoutingModule
  ],
  exports: [UeberComponent]
})
export class UeberModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeschichteRoutingModule } from './geschichte-routing.module';
import { GeschichteComponent } from './geschichte.component';


@NgModule({
  declarations: [GeschichteComponent],
  imports: [
    CommonModule,
    GeschichteRoutingModule
  ],
  exports: [GeschichteComponent]
})
export class GeschichteModule { }

<div id="content">
  <div id="ueberschrift">
    <h1>Ausrüstung und Feuerwehrhaus</h1>
  </div>
  
  <div id="fahrzeuge" class="background">
    <h3>Fahrzeuge</h3>
    <!-- LF20 KatS -->
    <div id="lf20kats">
      <h4>LF20 KatS - Löschgruppenfahrzeug Katastrophenschutz</h4>
      <h5>Unser neues Fahrzeug seit 03. September 2021</h5>
      <div>
        <img src="../../assets/03_LF20KatS.jpg" alt="">
      </div>
      
    </div>

    <!-- MZF -->   
    <div id="mzf">
      <h4>MZF - Mehrzweckfahrzeug</h4>
      <div>
        <img src="../../assets/03_MZF.JPG" alt="">
      </div>
    </div>
    
    <!-- LF8 -->
    <div>
      <h4>LF8 - Löschfahrzeug</h4>
      <h5>unser täglicher Begleiter bis September 2021</h5>
      <div id="lf8">
        <div id="visible_lf8" (click)="showLF8()">
          <img src="../../assets/03_LF8_01.JPG" alt="" >
        </div>
      </div>
    </div>
    <div id="hidden_lf8">
      <div (click)="notShowLF8()" id="zurueck">
        <p><b>&#10007;</b></p>
      </div>
      
      <div id="lf8_content">
        <img src="../../assets/03_LF8_02.JPG" alt="">
          <ul>
            <li>B- und C-Schläuche</li> 
            <li>Schlauchtragekörbe</li>
            <li>Feuerwehraxt</li> 
            <li>Tauchpumpe</li>
            <li>Verteiler</li>
            <li>Druckminderer</li>
            <li>Atemschutzgeräte</li>
            <li>Kabeltrommel</li>
            <li>Verkehrsleitkegel</li>
            <li>Reduzierstück von B nach C </li>
            <li>...</li>
          </ul>
        
        
        <img src="../../assets/03_LF8_03.JPG" alt="">
          <ul>
            <li>B- und C-Schläuche</li> 
            <li>B-, C- und D-Strahlrohre, Hohlstrahlrohre</li>
            <li>Schlauchhaspel mit C-Schläuchen</li>
            <li>Kettensäge</li>
            <li>Flutlichtstrahler mit Stativ</li>
            <li>Stromerzeuger</li>
            <li>...</li>
          </ul>
        
        <img src="../../assets/03_LF8_04.JPG" alt="">
          <ul>
            <li>Saugschläuche</li>
            <li>Tragbare Pumpe (Hale PowerFlow 17/10)</li>
            <li>Saugkorb</li>
            <li>Schwimmsaugkorb</li>
            <li>Kupplungsschlüssel</li>
            <li>Hydrantenschlüssel</li>
            <li>Standrohr und Unterflurhydrantenschlüssel</li>
            <li>Sammelstück</li>
            <li>...</li>
          </ul>
        
        <div>
          <p>Auf dem Feuerwehrauto:</p>
          <ul>
            <li>Steckleiter</li>
            <li>mobiler Wasserwerfer</li>
            <li>Schaufeln und Besen</li>
            <li>Schwerschaumrohr</li>
            <li>Mittelschaumrohr</li>
            <li>Schlauchbrücken</li>
            <li>Scheinwerfer</li>
            <li>Einreißhaken</li>
            <li>...</li>
          </ul>
        </div>
        
        <div>
          <p>Im Mannschaftsraum:</p>
          <ul>
            <li>Funkgeräte</li>
            <li>Rettungsleinen</li>
            <li>Krankentrage</li>
            <li>Schaumbindemittel</li>
            <li>Systemtrenner</li>
            <li>Hochdrucklöscher</li>
            <li>Erste-Hilfe-Rucksack</li>
            <li>diverse Handwerkzeuge</li>
            <li>...</li>
          </ul>
        </div>
        
        <div>
          <p>Pumpe vorne am Auto:</p>
          <ul>
            <li>Feuerlöschkreiselpumpe 16/8</li>
          </ul>
        </div>
        
      </div>
    </div>

    <!-- TSA -->   
    <div id="tsa">
      <h4>Tragkraftspritzenanhänger</h4>
      <h5>bis 1990</h5>
      <div>
        <img src="../../assets/03_tsa.jpg" alt="">
      </div>
    </div>


  </div>

  <div id="geraetehaus" class="background"  >
    <h3>Gerätehaus</h3>
    <div>
      <img id="img_gerätehaus_02" src="../../assets/03_Gerätehaus_01.JPG" alt="">
      <img id="img_gerätehaus_03" src="../../assets/03_Fahrzeughalle.JPG" alt="">
    </div>
  </div>
  
  <div id="schulungsraum" class="background"  >
    <h3>Schulungsraum</h3>
    <div>
      <img src="../../assets/03_Schulungsraum_01.JPG" alt="">
      <img src="../../assets/03_Schulungsraum_02.JPG" alt="">
    </div>
  </div>

</div>

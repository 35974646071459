import { Component, HostBinding, HostListener, Output } from '@angular/core';
import { compileNgModule } from '@angular/compiler';
import { HammerGestureConfig } from '@angular/platform-browser';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { EventEmitter } from 'protractor';
import { VereinComponent } from './verein/verein.component';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive, ROUTES } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'pl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FwFalkendorf';
  navbarOpen = false;
  private cookieValue: string;

  constructor(private router:Router){

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });

    window.onresize = function(){
      resizeVerein();
      resizeContent();
    }
    window.onload= function(){
      resizeVerein();
      resizeContent();
    }

    function resizeVerein(){
      var widthVerein = document.getElementById("verein").getBoundingClientRect().width;
      document.getElementById("verein2").style.width = widthVerein.toString() + "px";
      if (document.getElementById("verein") != null){
        var vereinLeft = (document.getElementById("verein").getBoundingClientRect().left);
        var verein2Left = document.getElementById("verein2");
        var left = (vereinLeft-0).toString() + "px";
        verein2Left.style.left = left;
      }
      else {
        console.log('nothing');
      }
    };

    function resizeContent(){
      var margin1 = document.getElementById("kopf").getBoundingClientRect().height;
      document.getElementById("content").style.marginTop = margin1.toString() + "px";
    };
    
    
  }

//Navbar
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
// Verein
  showVerein(){
    document.getElementById("verein2").style.display = "flex";
  }
  notShowVerein(){
    document.getElementById("verein2").style.display = "none";
  }
  showBackground(){
   document.getElementById('verein').style.backgroundColor = 'rgba(255, 255, 255, 0.26)';
  }
  notShowBackground(){
    document.getElementById('verein').style.backgroundColor = 'rgb(130, 0, 0)';
   }
  vereinBackground(){
    document.getElementById('verein').style.backgroundColor = 'rgba(255, 255, 255, 0.26)';
  }
  notVereinBackground(){
    document.getElementById('verein').style.backgroundColor = 'rgb(130, 0, 0)';
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UeberComponent } from './ueber/ueber.component';
import { AusruestungComponent } from './ausruestung/ausruestung.component';
//import { EinsaetzeComponent } from './einsaetze/einsaetze.component';
import { DienstplanComponent } from './dienstplan/dienstplan.component';
import { VereinComponent } from './verein/verein.component';
import { MitgliedComponent } from './mitglied/mitglied.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { AusbildungComponent } from './ausbildung/ausbildung.component';
import { GeschichteComponent } from './geschichte/geschichte.component';
//import { KontaktComponent } from './kontakt/kontakt.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'ueber', component: UeberComponent},
  {path: 'ausruestung', component: AusruestungComponent},
  {path: 'ausbildung', component: AusbildungComponent},
  //{path: 'einsaetze', component: EinsaetzeComponent},
  {path: 'dienstplan', component: DienstplanComponent},
  {path: 'verein', component: VereinComponent},
  {path: 'geschichte', component: GeschichteComponent},
  {path: 'mitglied', component: MitgliedComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  //{path: 'kontakt', component: KontaktComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div id="content">
  <div id="platzhalter"> 
    <h1> </h1>
  </div>
  <!-- <div id="ueberschrift">
    <h1>Einsätze</h1>
  </div> -->

  <!-- 
  <div id="jahr_2023">
    <div class="jahr">
      <h3>Einsätze im Jahr 2023</h3>
    </div>

    <div class="border">
      <div class="pfeil">
        <p id="pfeil_visible_2023" (click)="showEinsatz2023()" class="pfeil_visible"><b>&#9660;</b></p>
        <p id="pfeil_hidden_2023" (click)="notShowEinsatz2023()"><b>&#9650;</b></p>
      </div>
  
      <div id="einsätze_2023" class="einsätze">
        
        <div class="einsatz">
          <div id="visible_2023_01" (click)="show2023('01')" class="visible">
            <img src="../../assets/05_einsatz_180123.jpeg" alt="">
            <h4>Verkehrsunfall</h4>
            <div>
              <p><i>18.01.2023 18:50 - 21:50</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2023_01">
            <p><b>Verkehrsunfall</b>
              <br><i>18.01.2023 18:50 - 21:50</i>
              <br>Hauptstraße
              <br><br>Ein Fahrradfahrer blieb aus unerklärlichen Gründen an den Mülleimern hängen und geriet dabei auf die Gegenfahrbahn.
              Ein vorbeifahrendes Auto erfasste ihn dabei. Trotz aller Mühen der Einsatzkräfte erlag der Fahrrradfahrer seinen Verletzungen.
              Bei solchen Einsätzen ist es üblich, im Nachhinein einen Seelsorger anzufordern, um mit den Kameraden und Kameradinnen den Einsatz 
              durchzusprechen und psychisch belastende Situationen zu entschärfen, um das Erlebte besser zu verarbeiten. 
              <br><br>Unterstützung: Polizei, Rettungsdienst
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2023('01')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div> -->


  <!-- 
  <div id="jahr_2022">
    <div class="jahr">
      <h3>Einsätze im Jahr 2022</h3>
    </div>

    <div class="border">
      <div class="pfeil">
        <p id="pfeil_visible_2022" (click)="showEinsatz2022()" class="pfeil_visible"><b>&#9660;</b></p>
        <p id="pfeil_hidden_2022" (click)="notShowEinsatz2022()"><b>&#9650;</b></p>
      </div>
  
      <div id="einsätze_2022" class="einsätze">

        <div class="einsatz">
          <div id="visible_2022_23" (click)="show2022('23')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>Unterstützung Rettungsdienst</h4>
            <div>
              <p><i>29.11.2022 09:43 - 10:40</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_23">
            <p><b>Unterstützung Rettungsdienst</b>
              <br><i>29.11.2022 09:43 - 10:40</i>
              <br>Röthenäckerstraße 
              <br><br>Manchmal kann es vorkommen, dass alle Rettungswagen schon im Einsatz sind. Dann wird die Feuerwehr als Helfer/Sanitäter
              alarmiert und übernimmt den oder die Patienten, bis ein freier Rettungswagen an die Einsatzstelle kommt. Denn bei ernsten Verletzungen 
              oder Krankheiten zählt jede Minute der Hilfe. Dafür werden Feuerwehrleute ebenso in Erster Hilfe bzw. Sanitätsdienst ausgebildet.
              Genau dies war an diesem Tag der Fall. Die Feuerwehr hat sich um den Patienten gekümmert, bis ein Rettungswagen gekommen ist.
              <br><br>Unterstützung: Rettungsdienst, Rettungshubschrauber
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('23')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_22" (click)="show2022('22')" class="visible">
            <img src="../../assets/05_einsatz_111122.jpeg" alt="">
            <h4>Laternenumzug Kindergarten</h4>
            <div>
              <p><i>11.11.2022 16:30 - 18:10</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_22">
            <p><b>Laternenumzug Kindergarten</b>
              <br><i>11.11.2022 16:30 - 18:10</i>
              <br>Falkendorf 
              <br><br>Jedes Jahr verantstaltet der Kindergarten Falkendorf wie viele einen Laternenumzug. Damit die Kinder mit Eltern und Betreuern dabei sicher auf der 
              Straße laufen können, sperrt die Feuerwehr die Straßen im Wohngebiet ordnungsgemäß ab. So können sich die Kleinen 
              ganz auf den Umzug mit den selbstgebastelten Laternen und den vielen Lichtern konzentrieren.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('22')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_21" (click)="show2022('21')" class="visible">
            <img src="../../assets/05_einsatz_291022.jpeg" alt="">
            <h4>Ölspur</h4>
            <div>
              <p><i>29.10.2022 17:35 - 19:00</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_21">
            <p><b>Ölspur</b>
              <br><i>29.10.2022 17:35 - 19:00</i>
              <br>Hauptstraße, Dörflaser Weg 
              <br><br>Eine Autofahrerin hatte bemerkt, dass ihr Auto Öl verloren hatte, blieb am Straßenrand stehen und rief die Feuerwehr.
              Da die meisten Kammeraden /-innen schon am Feuerwehrhaus waren, da die Vorbereitungen für unser jährliches Herbstgrillen anstand,
              waren wir schnell vor Ort und die Ölspur konnte schnell beseitigt werden. 
              <br><br>Unterstützung: Polizei
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('21')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_20" (click)="show2022('20')" class="visible">
            <img src="../../assets/05_einsatz_101022.jpeg" alt="">
            <h4>Rauchentwicklung im Gebäude</h4>
            <div>
              <p><i>10.10.2022 21:47 - 23:15</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_20">
            <p><b>Rauchentwicklung im Gebäude</b>
              <br><i>10.10.2022 21:47 - 23:15</i>
              <br>Hammerbach 
              <br><br>In einem Gebäude im Keller hatten mehrere Papiere gebrannt. Durch das schnelle Eingreifen der Feuerwehr konnte 
              jedoch ein weiteres Ausbreiten des Feuers verhindert werden. Die Bewohner des Gebäudes konnten sich in Sicherheit bringen und 
              wurden nicht verletzt. 
              <br><br>Unterstützung: Feuerwehr Hammerbach, Feuerwehr Herzogenaurach, Polizei
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('20')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_19" (click)="show2022('19')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>Ölspur</h4>
            <div>
              <p><i>08.10.2022 18:22 - 19:28</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_19">
            <p><b>Ölspur</b>
              <br><i>08.10.2022 18:22 - 19:28</i>
              <br>Falkendorf Richtung Dondörflein
              <br><br>Zwischen Falkendorf und Dondörflein wurde eine Ölspur entdeckt. Die Feuerwehr konnte diese mit 
              Ölbindemittel abbinden und so die Umwelt vor weiterer Verschmutzung retten.
              <br><br>Unterstützung: Polizei
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('19')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_18" (click)="show2022('18')" class="visible">
            <img src="../../assets/05_einsatz_230922.jpeg" alt="">
            <h4>Verkehrsunfall</h4>
            <div>
              <p><i>23.09.2022 06:25 - 07:35</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_18">
            <p><b>Verkehrsunfall</b>
              <br><i>23.09.2022 06:25 - 07:35</i>
              <br>Falkendorf Richtung Buch
              <br><br>Früh am Morgen ereilte uns die Meldung, dass ein PKW von der Fahrbahn abgekommen und auf dem Dach im Wald gelandet sei.
              Am Unfallort stellte sich jedoch schnell heraus, dass es dem Patienten gut ging und er sich selbst aus dem verunfallten Wagen retten konnte.
              Dabei hatte er sich zum Glück keine großen Verletzungen zugezogen.
              <br><br>Unterstützung: Polizei, Rettungsdienst, Notarzt
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('18')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_17" (click)="show2022('17')" class="visible">
            <img src="../../assets/05_einsatz_170922.jpeg" alt="">
            <h4>Absperren Kerwasbaum</h4>
            <div>
              <p><i>17.09.2022</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_17">
            <p><b>Absperren Kerwasbaum</b>
              <br><i>17.09.2022</i>
              <br>Hauptstraße 
              <br><br>Unsere Ortsburschen und -Madle aus Falkendorf haben zur jährlichen Kerwa wieder einen Kerwabaum geholt und 
              auf dem Gelände der Kerwa aufgestellt. Tradition hierbei ist es, beim holen des Baumes aus dem Wald mit eben diesem Baum 
              durch die Ortschaft zu fahren, bevor er aufgestellt wird. Damit den Ortsburschen und -Madle dabei jedoch nichts passiert, 
              hat die Feuerwehr mit ein paar Kameraden für die Sicherheit auf der Straße gesorgt.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('17')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_16" (click)="show2022('16')" class="visible">
            <img src="../../assets/05_einsatz_130922.jpeg" alt="">
            <h4>Verkehrsunfall</h4>
            <div>
              <p><i>13.09.2022 13:50 - 14:35</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_16">
            <p><b>Verkehrsunfall</b>
              <br><i>13.09.2022 13:50 - 14:35</i>
              <br>Hauptstraße
              <br><br>Ein Motorradfahrer wurde beim Abbiegen von einem Auto abgedrängt. Er fuhr gegen eine Verkehrsinsel und stürzte 
              auf die Fahrbahn. Dabei wurde der Motorradfahrer leicht verletzt.
              <br><br>Unterstützung: Feuerwehr Münchaurach, Polizei, Rettungsdienst
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('16')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_15" (click)="show2022('15')" class="visible">
            <img src="../../assets/05_einsatz_26082022.jpeg" alt="">
            <h4>Unwetter</h4>
            <div>
              <p><i>26.08.2022 17:39 - 18:15</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_15">
            <p><b>Unwetter</b>
              <br><i>26.08.2022 17:39 - 18:15</i>
              <br>Hessenmühle
              <br><br>Durch das Unwetter lief Wasser in den Reitstall. Pferde und Menschen wurden dabei nicht verletzt.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('15')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_14" (click)="show2022('14')" class="visible">
            <img src="../../assets/05_einsatz_08082022.jpeg" alt="">
            <h4>Rauchentwicklung</h4>
            <div>
              <p><i>08.08.2022 08:40 - 08:50</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_14">
            <p><b>Rauchentwicklung</b>
              <br><i>08.08.2022 08:40 - 08:50</i>
              <br>Hauptstraße
              <br><br>An der Bushaltestelle Falkendorf Ort hatte sich der Inhalt des Mülleimers entzündet. Die Gemeindemitarbeiter vor Ort konnten das Feuer löschen, 
              bevor die Feuerwehr eintraf.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('14')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_13" (click)="show2022('13')" class="visible">
            <img src="../../assets/05_einsatz_20072022.jpeg" alt="">
            <h4>Rauchentwicklung</h4>
            <div>
              <p><i>20.07.2022 16:05 - 16:45</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_13">
            <p><b>Rauch</b>
              <br><i>20.07.2022 16:05 - 16:45</i>
              <br>Felder und Wälder Richtung Buch/Hammerbach
              <br><br>Ein besorgter Bürger hatte die Feuerwehr gerufen, weil er Rauch in der Landschaft gesichtet hatte. Die Feuerwehren untersuchten die
               Umgebung, fanden jedoch zum Glück keinen Brandherd.
              <br><br>Unterstützung: Feuerwehr Buch, Feuerwehr Hammerbach, Rettungsdienst
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('13')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_12" (click)="show2022('12')" class="visible">
            <img src="../../assets/05_einsatz_19072022.jpeg" alt="">
            <h4>Ölspur</h4>
            <div>
              <p><i>19.07.2022 14:19 - 14:55</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_12">
            <p><b>Ölspur</b>
              <br><i>19.07.2022 14:19 - 14:55</i>
              <br>Falkendorf Richtung Herzogenaurach
              <br><br>Durch ausgelaufene Betriebsmittel wurde die Umwelt verschmutzt. Darum kümmerte sich die Feuerwehr und beseitigte diese Ölspur mit Ölbindemittel.
              <br><br>Unterstützung: Polizei
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('12')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_11" (click)="show2022('11')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>Herzoman</h4>
            <div>
              <p><i>17.07.2022 08:00 - 12:15</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_11">
            <p><b>Herzoman</b>
              <br><i>17.07.2022 08:00 - 12:15</i>
              <br>Falkendorf
              <br><br>Dieses Jahr fand wieder der Herzoman statt, bei dem die Teilnehmer nicht nur schwimmen und laufen, sondern auch Fahrrad fahren.
              Die Strecke ging dabei durch Falkendorf, weshalb die Feuerwehr ein Auge auf die in der Strecke enthaltenen Kurven hatte, damit nichts geschah. Denn die Teilnehmer
               fuhren im normalen Straßenverkehr.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('11')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>
        
        <div class="einsatz">
          <div id="visible_2022_10" (click)="show2022('10')" class="visible">
            <img src="../../assets/05_einsatz_25062022.jpeg" alt="">
            <h4>Johannisfeuer</h4>
            <div>
              <p><i>25.06.2022 21:00</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_10">
            <p><b>Johannsisfeuer</b>
              <br><i>25.06.2022 21:00</i>
              <br>Hellerhalle
              <br><br>Die Veranstalter des Fischerfestes haben die Feuerwehr angefragt, ob sie die Feuerwache bei ihrem Johannisfeuer
               übernehmen könnten. Die Feuerwehr baute als Vorsichtsmaßnahme alles nötige auf, sodass sie eingreifen konnten, sobald das Feuer sich 
               unkontrolliert ausbreiten sollte. Dies geschah nur im kleinem Maße, denn das Feuer sprang auf die Wiese über und verkohlte diese allmählich.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('10')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_09" (click)="show2022('09')" class="visible">
            <img src="../../assets/05_einsatz_24062022.jpeg" alt="">
            <h4>Unwetter</h4>
            <div>
              <p><i>24.06.2022 17:53 - 18:50</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_09">
            <p><b>Unwetter</b>
              <br><i>24.06.2022 17:53 - 18:50</i>
              <br>mehrere Orte
              <br><br>Das Unwetter ließ einen Baum auf die Straße stürzen und Wasser in den Reiterstall fließen.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('09')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_08" (click)="show2022('08')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>Verkehrsunfall</h4>
            <div>
              <p><i>08.06.2022 09:59 - 10:25</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_08">
            <p><b>Verkehrsunfall</b>
              <br><i>08.06.2022 09:59 - 10:25</i>
              <br>Neundorf Richtung Oberniederndorf
              <br><br>Ein Auto wollte ein landwirtschaftliches Fahrzeug in einer Kurve überholen. Dabei übersah der Fahrer das herankommende Auto auf der Gegenfahrbahn.
                      Die schon vor Ort befindlichen Feuerwehren und Rettungswagen kümmerten sich um die Verletzten und den Unfall, sodass die Feuerwehr Falkendorf schnell wieder abrücken konnte.
              <br><br>Unterstützung: Feuerwehr Münchaurach, Neundorf, Rettungsdienst, Feuerwehr Herzogenaurach, ...
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('08')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_07" (click)="show2022('07')" class="visible">
            <img src="../../assets/05_einsatz_20052022.jpeg" alt="">
            <h4>Unwetter</h4>
            <div>
              <p><i>20.05.2022 18:19 - 20:30</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_07">
            <p><b>Unwetter</b>
              <br><i>20.05.2022 18:19 - 20:30</i>
              <br>mehrere Orte
              <br><br>Durch das Unwetter musste die Feuerwehr einen Baum von der Straße holen und mehrere Keller/Häuser liefen voll Wasser.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('07')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_06" (click)="show2022('06')" class="visible">
            <img src="../../assets/05_einsatz_19052022.jpeg" alt="">
            <h4>Unwetter</h4>
            <div>
              <p><i>19.05.2022 21:39 - 22:20</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_06">
            <p><b>Unwetter</b>
              <br><i>19.05.2022 21:39 - 22:20</i>
              <br>Milchhausstraße
              <br><br>Durch das Unwetter lief Wasser in eine Wohnung, das jedoch von der Feuerwehr rasch wieder herausgepumpt werden konnte.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('06')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_05" (click)="show2022('05')" class="visible">
            <img src="../../assets/05_einsatz_14052022.jpeg" alt="">
            <h4>Ölspur</h4>
            <div>
              <p><i>14.05.2022 11:27</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_05">
            <p><b>Ölspur</b>
              <br><i>14.05.2022 11:27</i>
              <br>Hauptstraße
              <br><br>Die Ölspur konnte von der Feuerwehr rasch beseitigt werden.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('05')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_04" (click)="show2022('04')" class="visible">
            <img src="../../assets/05_einsatz_060422.jpg" alt="">
            <h4>Brand</h4>
            <div>
              <p><i>06.04.2022 19:24 - 20:20</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_04">
            <p><b>Brand</b>
              <br><i>06.04.2022 19:24 - 20:20</i>
              <br>Röthenäckerstraße
              <br><br>Die Meldung war Brand. Schnell stellte sich heraus, das dieser Einsatz kein gewöhnlicher war, sondern eine Einsatzübung. 
              Zwei der Kameraden meldeten diese bei der Leitstelle an und schürten ein kontrolliertes Feuer, woraufhin sie die Feuerwehr riefen. 
              Die anderen Feuerwehrkameraden und -kameradinnen, die davon nichts wussten, gingen von einem Brand aus. An der Einsatzstelle stellte 
              sich jedoch heraus, dass dies eine Übung war. Trotzdem wurde das Feuer rasch gelöscht. Nach der Übung fand noch eine sogenannte 
              Nachbesprechung statt, in der besprochen wurde, was gut lief und was man besser machen könnte.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('04')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_03" (click)="show2022('03')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>THL Person eingeschlossen</h4>
            <div>
              <p><i>23.03.2022 14:51 - 15:12</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_03">
            <p><b>THL Person eingeschlossen</b>
              <br><i>23.03.2022 14:51 - 15:12</i>
              <br>Röthenäckerstraße
              <br><br>Ein kleines Kind hatte sich ausversehen eingeschlossen und konnte selbstständig nicht mehr aus dem Raum. 
              Deshalb wurde die Tür mithilfe der Feuerwehr geöffnet, sodass das Kind unbeschadet wieder den Eltern übergeben werden konnte.
              <br><br>Unterstützung: Feuerwehr Herzogenaurach, Sanitäter
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('03')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_02" (click)="show2022('02')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>Wasserschaden</h4>
            <div>
              <p><i>18.03.2022 18:30 - 19:30</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_02">
            <p><b>Wasserschaden</b>
              <br><i>18.03.2022 18:30 - 19:30</i>
              <br>Flurstraße
              <br><br>Durch Bauarbeiten wurde ein Wasserrohr beschädigt. Die Feuerwehr hat daraufhin einen Graben leergepumpt, 
              da das dort enthaltene Wasser drohte, in ein angrenzendes Haus zu laufen.
              <br><br>Unterstützung: keine
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('02')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>

        <div class="einsatz">
          <div id="visible_2022_01" (click)="show2022('01')" class="visible">
            <img src="../../assets/05_sirene.JPG" alt="">
            <h4>Brand im Gebäude</h4>
            <div>
              <p><i>21.02.2022 01:03 - 01:40</i></p>
              <p class="pfeil">&#10137;</p>
            </div>
          </div>
          <div class="hidden" id="hidden_2022_01">
            <p><b>Brand im Gebäude</b>
              <br><i>21.02.2022 01:03 - 01:40</i>
              <br>Münchaurach
              <br><br>In der Nacht ging in Falkendorf und Umkreis die Sirene. Das Stichwort lautete B3 - Brand in Zimmer/Wohnung. 
              Zum Glück war es nichts ernstes und der brennende Mülleimer war schnell gelöscht.
              <br><br>Unterstützung: Feuerwehr Münchaurach, Feuerwehr Neundorf, Feuerwehr Herzogenaurach, Sanitäter
            </p>
            <div class="div_pfeil">
              <p (click)="notShow2022('01')" class="pfeil"><b>&#10007;</b></p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>-->

  
</div>
<div id="content">
  <div id="willkommen">
    <img id="pct_willkommen" src="../../assets/01_willkommen.jpg" alt="fd">
    <img id="pct_willkommen_klein" src="../../assets/01_willkommen_klein.jpg" alt="fd">
    <h1>Herzlich Willkommen<br>bei der<br>Freiwilligen Feuerwehr Falkendorf</h1>
  </div>

  <div id="links">
    <div id="uberuns" class="link">
      <a routerLink="../ueber" routerLinkActive="active-route">
        <img src="../../assets/01_uberuns.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Über uns</h2>
    </div>
    <div id="ausrustung" class="link">
      <a routerLink="../ausruestung" routerLinkActive="active-route">
        <img src="../../assets/01_ausrustung.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Ausrüstung</h2>
    </div>
    <div id="ausbildung" class="link">
      <a routerLink="../ausbildung" routerLinkActive="active-route">
        <img src="../../assets/01_ausbildung.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Ausbildung</h2>
    </div>
  <!-- 
    <div id="einsatze" class="link">
      <a routerLink="../einsaetze" routerLinkActive="active-route">
        <img src="../../assets/01_einsatze.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Einsätze</h2>
    </div>
    -->
    <div id="dienstplan" class="link">
      <a routerLink="../dienstplan" routerLinkActive="active-route">
        <img src="../../assets/01_dienstplan.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Dienstplan</h2>
    </div>
    <div id="vorstand" class="link">
      <a routerLink="../verein" routerLinkActive="active-route">
        <img src="../../assets/01_vorstandschaft.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Vorstandschaft</h2>
    </div>
    <div id="geschichte" class="link">
      <a routerLink="../geschichte" routerLinkActive="active-route">
        <img src="../../assets/01_verein.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Geschichte</h2>
    </div>
    <div id="machmit" class="link">
      <a routerLink="../mitglied" routerLinkActive="active-route">
        <img src="../../assets/01_machmit.jpg" alt="" class="link_pct">
      </a>
      <h2 class="link_h">Mach mit!</h2>
    </div>
  </div>
<!--
  <div id="neuigkeiten">
    <h3>Neuigkeiten</h3>
    <p>-
    </p>
  </div>
-->
<!--
  <div id="veranstaltung">
  </div>-->

<!--
  <div id="kontakt">
    <p>Kontakt: <br><br>Freiwillige Feuerwehr Falkendorf <br>Hauptstraße 1 <br>91086 Aurachtal 
      <br><br>ff-falkendorf@web.de</p>
    
      <a href="https://www.google.com/maps/place/Freiwillige+Feuerwehr+Falkendorf/@49.5788412,10.83863,15z/data=!4m5!3m4!1s0x0:0xc1f08097d881fac!8m2!3d49.5788412!4d10.83863">
      <img src="../../assets/01_Karte.png" >
    </a>
    
  </div>
 -->
</div>